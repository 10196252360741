<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<div class="space-y-5">
								<p>When experiencing issues please start by following the below troubleshooting steps.</p>
								<div>
									<p><span class="font-semibold">1.Clear your device cache and force closing the app</span> and then try again.</p>
									<p>-Devices such as firesticks are great devices but they require regular maintenance to perform at their best</p>
								</div>
								<div class="space-y-1">
									<p class="font-semibold">To clear the cache on your firestick:</p>
									<p>-Start up your Firestick and begin at the main menu.</p>
									<p>-Scroll to the right and find the <span class="font-semibold">Settings</span> menu</p>
									<p>-Select <span class="font-semibold">Applications</span></p>
									<p>-Select Manage Installed Applications</p>
									<p>-You will now see a list of the installed applications on your firestick</p>
									<p>-Scroll down and select the app your having issues with.</p>
									<p>-Select <span class="font-semibold">Clear Cache. (Click Yes to clear cache if prompted)</span></p>
									<p>-Select <span class="font-semibold">Force Close</span></p>
									<p>-Feel free to clear cache and force close any other apps. Delete any apps you no longer use to increase storage.</p>
								</div>
								<p>For other devices search google: "How do I clear cache on __________ "</p>
								<p>2. Reset/unplug your device for 1 minute and then try again after rebooting the device.</p>
								<p>3. Make sure your using the latest app version available for your device. You may need to delete and reinstall the app.</p>
								<p>
									Visit <a class="hover:text-blue-500" :href="$store.state.brandInfo.siteLink">{{ $store.state.brandInfo.faqLink }}</a> and select your device for
									step by step installation guides.
								</p>
								<p>
									4. If the problem persists after these steps have been completed open up a support ticket or email us at
									<a :href="`mailto:${$store.state.brandInfo.supportEmail}`" class="hover:text-blue-500 font-semibold">{{
										$store.state.brandInfo.supportEmail
									}}</a>
								</p>
								<p>
									5.<span class="underline">Important</span>: Be sure to let us know what Device (LGTV, firestick, ipad, iphone, android box, etc) and apps you
									are using in order for us to help you as quickly as possible.
								</p>
								<p>In {{ $store.state.brandInfo.appName }} app, there's ability to switch the player type, for both VOD and LiveTV.</p>
								<p>
									This can help when experiencing audio or video issues such as no sound issue, sound out of sync, background noise only, video not starting, or
									no video and hearing only sound.
								</p>
								<div>
									<p>Some devices run in to some issues when using a particular player, thus the ability to change between players.</p>
									<p>Usually this helps when one experience issues with video and audio out of sync.</p>
								</div>
								<div class="space-y-3">
									<p>TV:</p>
									<p>Click on Profile at the top left:</p>
									<img class="w-9/12" src="../../../assets/images/mini-icon/in-theaters.png" alt="in-theaters" />
								</div>
								<div class="space-y-3">
									<p>Then change the Player Type based on the content you want to switch (LiveTV or VOD)</p>
									<img class="w-9/12" src="../../../assets/images/mini-icon/profile-settings.png" alt="profile-settings" />
								</div>
								<p>Mobile: you can see it under "More" setting at the bottom part:</p>
								<img class="w-5/12" src="../../../assets/images/mini-icon/profile-settings-m.png" alt="profile-settings-mobile" />
								<p>These are the default, but if you run to any issue, you can always change it back</p>
							</div>
							<hr />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'GeneralTroublesHooting',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
